'use client';

import { AriIconLogo } from '../../ui/Logo';
import I18nProvider from '../../i18n/I18nProvider';
import Toaster from '../../components/Toaster';
import CsrfTokenContext from '../../lib/contexts/csrf';
import { AuthFormFooter } from '../AuthFormFooter';
import { cn } from '../../lib/utils';
import { LoadUnAuthenticatedDataReturnType } from '../../lib/server/loaders';

type Props = React.PropsWithChildren<LoadUnAuthenticatedDataReturnType>;

export default function AuthPageLayout({
  children,
  language,
  csrfToken,
}: Props) {
  return (
    <CsrfTokenContext.Provider value={csrfToken}>
      <div className="h-screen flex flex-col">
        <div
          className={cn(
            'h-full flex flex-col items-center md:justify-center space-y-4',
            'animate-in fade-in slide-in-from-top-8 duration-1000',
            'md:space-y-8 lg:space-y-16  dark:lg:bg-background'
          )}
        >
          <div
            className={cn(
              `w-full max-w-sm space-y-4 rounded-3xl border-transparent bg-background px-6 py-6`,
              `bg-dot-gray-sm bg-no-repeat bg-right-top`,
              `dark:bg-background dark:shadow-[0_0_1200px_0] dark:shadow-primary/30`,
              `md:w-8/12 md:border md:shadow-xl dark:md:border-dark-800`,
              `lg:w-5/12 xl:w-4/12 2xl:w-3/12`
            )}
          >
            <AriIconLogo className="w-11 h-11 mx-auto" />
            <div className="flex flex-col">
              <I18nProvider lang={language}>
                <Toaster
                  className="text-center [&>li]:justify-center [&>li]:w-full"
                  style={
                    {
                      '--width': '100%',
                      '--offset': '4px',
                    } as React.CSSProperties
                  }
                />
                {children}
              </I18nProvider>
            </div>
          </div>
        </div>

        <AuthFormFooter />
      </div>
    </CsrfTokenContext.Provider>
  );
}
