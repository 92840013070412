import { atom } from 'jotai';
import { createMachine } from 'xstate';
import { atomWithMachine } from 'jotai-xstate';
import { CommunitiesMap, SubscriptionCommunity } from './types';

export enum WorkflowSteps {
  BenefitsLearnMore = 'BenefitsLearnMore',
  ActivationSummary = 'ActivationSummary',
  CommunitySelection = 'CommunitySelection',
}

export const isSubscriptionDialogAtom = atom<boolean>(true);

const subscriptionWorkflowMachine = (value: string) => {
  return createMachine({
    id: 'subscription',
    initial: WorkflowSteps.BenefitsLearnMore,
    context: {
      value,
    },
    states: {
      [WorkflowSteps.BenefitsLearnMore]: {
        on: {
          next: WorkflowSteps.ActivationSummary,
        },
      },
      [WorkflowSteps.ActivationSummary]: {
        on: {
          previous: WorkflowSteps.BenefitsLearnMore,
          edit: WorkflowSteps.CommunitySelection,
        },
      },
      [WorkflowSteps.CommunitySelection]: {
        on: {
          next: WorkflowSteps.ActivationSummary,
          previous: WorkflowSteps.ActivationSummary,
        },
      },
    },
  });
};

export const workflowMachineAtom = atomWithMachine(() => {
  return subscriptionWorkflowMachine('');
});

const communitiesAtom = atom(new Map<string, SubscriptionCommunity>());

export const initCommunitiesAtom = atom(
  null,
  (get, set, communities: CommunitiesMap) => {
    set(communitiesAtom, communities);
  }
);

export const allCommunitiesAtom = atom<CommunitiesMap>((get) => {
  return get(communitiesAtom);
});

export const cartAtom = atom<Array<SubscriptionCommunity>>([]);

export const toggleCommunityCheckAtom = atom(
  null,
  (get, set, communityId: string) => {
    const storedCommunities = new Map(get(communitiesAtom));
    const community = storedCommunities.get(communityId);

    if (!community) return;

    storedCommunities.set(communityId, {
      ...community,
      check: !community.check,
    });
    set(communitiesAtom, storedCommunities);
  }
);

export const selectAllCommunitiesAtom = atom(
  null,
  (get, set, check: boolean) => {
    const storedCommunities = new Map(get(communitiesAtom));

    storedCommunities.forEach((community, id) => {
      storedCommunities.set(id, { ...community, check });
    });

    set(communitiesAtom, storedCommunities);
  }
);

export const allowCommunityHistoryView = atom(false);
