import { atom } from 'jotai';
import { UserSession } from './types';
import {
  cachedCommunityAtom,
  setActiveCommunityAtomValue,
} from '../communities/atoms';

export const userSessionAtom = atom<UserSession | null>(null);

export const setUserSessionAtom = atom(
  null,
  (get, set, session: UserSession | null) => {
    const community = get(cachedCommunityAtom);
    const hasManagerUser = session?.user?.manager_id;
    const isCacheOwner = session?.user?.manager_id !== community?.userId;

    if (hasManagerUser && isCacheOwner) {
      set(setActiveCommunityAtomValue, null);
    }

    set(userSessionAtom, session);
  }
);
