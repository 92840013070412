import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getCookie = (key: string) => {
  try {
    return cookies.get(key);
  } catch (e) {
    console.error('Error reading cookie:', e);
    return null;
  }
};

export const setCookie = (key: string, value: string, options = {}) => {
  try {
    cookies.set(key, value, { path: '/', ...options });
  } catch (e) {
    console.error('Error setting cookie:', e);
  }
};

export const removeCookie = (key: string) => {
  try {
    cookies.remove(key, { path: '/' });
  } catch (e) {
    console.error('Error removing cookie:', e);
  }
};
