export enum CookieKeys {
  Auth = 'authToken',
  Domains = '.rentgrata.com',
  CSRF_TOKEN_HEADER = 'X-CSRF-Token',
  CSRF_SECRET = 'csrfSecret',
  ACCEPT_TERMS_OF_SERVICE = 'acceptTermsOfService',
  THEME_COOKIE_NAME = 'theme',
  SIDEBAR_STATE_COOKIE_NAME = 'sidebarState',
}

export enum ErrorTypes {
  NetworkError = 'Network Error',
  ClientError = 'Client Error',
}

export enum StorageKeys {
  ActiveCommunityIdStorage = 'active-community-id',
}

export enum UrlPaths {
  HOME = '/',
  SIGN_IN = '/sign-in',
  TERMS_OF_USE = '/onboarding/terms-of-use',
}

/**
 * @description: The maximum age of a token in seconds.
 */
export enum TokenMaxAge {
  EIGHT_HOURS = 8 * 60 * 60,
  ZERO = 0,
}
