import { apiHost, rgAPIHost } from './env';

export type ResponseError = Error & {
  ok: boolean;
  status: number;
  statusText: string;
  message: string;
  info: {
    message: string;
  };
};

export class APIFetch {
  private readonly baseURL: string;

  private headers: Record<string, string> = {
    'Content-Type': 'application/json',
  };

  constructor(baseURL: string) {
    this.baseURL = baseURL;
  }

  async handle(path: string, options: RequestInit) {
    try {
      const response = await fetch(this.getURL(path), options);

      if (!response.ok) {
        const errResp = await response.json();

        throw {
          ...(errResp || {}),
          ok: response.ok,
          status: response.status,
          statusText: response.statusText,
          info: {
            message: errResp.message,
          },
        };
      }

      return await response.json();
    } catch (error) {
      console.log('fetch error', error);
      throw error;
    }
  }

  addHeaders(headers: Record<string, string>) {
    Object.assign(this.headers, headers);

    return this;
  }

  authorize = (token?: string | null) => {
    const { Authorization, ...headers } = this.headers;

    if (token) {
      this.addHeaders({ Authorization: `Bearer ${token}` });
    } else {
      this.addHeaders(headers);
    }

    return this;
  };

  delete = async (url: string, props?: RequestInit) => {
    const options = {
      ...(props || {}),
      method: 'DELETE',
      headers: this.headers,
    };

    return this.handle(url, options);
  };

  get = async (path: string, props?: RequestInit) => {
    const options = {
      ...(props || {}),
      method: 'GET',
      headers: this.headers,
    };

    return this.handle(path, options);
  };

  patch = async (
    path: string,
    body?: Record<string, any>,
    props?: RequestInit | null
  ) => {
    const options = {
      ...(props || {}),
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify(body || {}),
    };

    return this.handle(path, options);
  };

  post = async (
    path: string,
    body: Record<string, any>,
    props?: RequestInit
  ) => {
    const options: RequestInit = {
      ...(props || {}),
      method: 'POST',
      headers: {
        ...this.headers,
        ...(props?.headers || {}),
      },
      body: JSON.stringify(body),
    };

    return this.handle(path, options);
  };

  private getURL(path: string) {
    return `${this.baseURL}${path.startsWith('/') ? path : `/${path}`}`;
  }
}

const version = 'v1';
export const baseURL = `${apiHost}/api/${version}`;
export const rgBaseURL = `${rgAPIHost}/api/rgenie`;

export const apiFetch = new APIFetch(baseURL);
export const rgApiFetch = new APIFetch(rgBaseURL);
