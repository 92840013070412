import { atom } from 'jotai/index';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import type { CachedCommunity } from './type';
import { StorageKeys } from '../../lib/constants';
import { allowCommunityHistoryView } from '../subscription/atoms';
import * as cookies from '../../lib/cookies';

const defaultCommunity: CachedCommunity = cookies.getCookie(
  StorageKeys.ActiveCommunityIdStorage
);

const communityIdStorage = createJSONStorage<CachedCommunity>(() => {
  return {
    getItem: cookies.getCookie,
    setItem: cookies.setCookie,
    removeItem: cookies.removeCookie,
  };
});

export const cachedCommunityAtom = atomWithStorage<CachedCommunity>(
  StorageKeys.ActiveCommunityIdStorage,
  defaultCommunity,
  communityIdStorage
);

const setCachedCommunityAtom = atom(
  null,
  (get, set, community: CachedCommunity | null) => {
    set(cachedCommunityAtom, community);
  }
);

export const activeCommunityAtom = atom<CachedCommunity | null>(null);

export const getActiveCommunityAtom = atom((get) => {
  const cachedCommunity = get(cachedCommunityAtom);
  const activeCommunity = get(activeCommunityAtom);

  return activeCommunity ?? cachedCommunity;
});

export const setActiveCommunityAtomValue = atom(
  null,
  (get, set, community: CachedCommunity | null) => {
    if (community?.id === get(activeCommunityAtom)?.id) return;

    set(activeCommunityAtom, community);
    set(setCachedCommunityAtom, community);
    set(allowCommunityHistoryView, false);
  }
);

export const searchQueryAtom = atom<string>('');

export const isCommunityDialogOpenAtom = atom<boolean>(false);
