import { CookieKeys } from './constants';

export enum Envs {
  CI = 'ci',
  STAGING = 'staging',
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
  LOCAL = 'local',
}

const appUrl = process.env.NEXT_PUBLIC_APP_URL || 'http://localhost:4200';
const allowedCookiesDomains =
  process.env.NEXT_PUBLIC_ALLOWED_COOKIE_DOMAINS || CookieKeys.Domains;
const bugsnagAPIKey = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || 'fake-key';
const fullStoryOrgId = process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID;
const userbackToken = process.env.NEXT_PUBLIC_USER_BACK_TOKEN;
const apiHost = process.env.NEXT_PUBLIC_API_HOST;
const rgAPIHost = process.env.NEXT_PUBLIC_RG_API_HOST;
const environment = process.env.NEXT_PUBLIC_APP_ENV || Envs.LOCAL;
const chatShareUrlPrefix = process.env.NEXT_PUBLIC_CHAT_SHARE_URL_PREFIX;
const managerAppUrl =
  process.env.NEXT_PUBLIC_MANAGER_APP_URL ?? 'https://manager.rentgrata.com';

const isTesting: boolean = Envs.CI === environment;
const isStaging: boolean = Envs.STAGING === environment;
const isProduction: boolean = Envs.PRODUCTION === environment;
const isDevelopment: boolean = Envs.DEVELOPMENT === environment;
const isLocal: boolean = Envs.LOCAL === environment;

const isSSR = typeof window === 'undefined';

const getEnv = (): string => {
  return Object.values(Envs).find((env) => {
    return env === environment;
  }) as string;
};

const getHostURL = (): string => {
  if (isSSR) return appUrl;

  return `${window.location.protocol}//${window.location.host}`;
};

export {
  apiHost,
  rgAPIHost,
  isTesting,
  isStaging,
  isProduction,
  isDevelopment,
  isLocal,
  isSSR,
  getEnv,
  getHostURL,
  userbackToken,
  fullStoryOrgId,
  bugsnagAPIKey,
  chatShareUrlPrefix,
  managerAppUrl,
  appUrl,
  allowedCookiesDomains,
};
